import React, { useEffect, useState } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import '../../styles.css';

const Companies = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompaniesData = async () => {
      const getCompaniesData = httpsCallable(functions, 'getAllCompanies');
      
      try {
        const result = await getCompaniesData();
        if (result.data.companies && Array.isArray(result.data.companies)) {
          setCompaniesData(result.data.companies);  // Ensure the data is an array
          setFilteredCompanies(result.data.companies);
        } else {
          setError('Unexpected data format');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching companies data:', error);
        setError('Error fetching companies data');
        setLoading(false);
      }
    };

    fetchCompaniesData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = companiesData.filter((company) =>
        Object.values(company).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredCompanies(results);
    } else {
      setFilteredCompanies(companiesData);
    }
  }, [searchTerm, companiesData]);

  const handleRowClick = (companyId) => {
    navigate(`/buyer-details?companyId=${companyId}`);
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <Container className="content"><p>{error}</p></Container>;
  }

  return (
    <Container className="content">
      <h1>Buyers Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Trading Name</th>
            <th>ABN</th>
            <th>Suburb</th>
            <th>State</th>
            <th>Company ID</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanies.map((company) => (
            <tr key={company.companyId} onClick={() => handleRowClick(company.companyId)} style={{ cursor: 'pointer' }}>
              <td>{company.companyName}</td>
              <td>{company.tradingName || 'N/A'}</td>
              <td>{company.ABN}</td>
              <td>{company.deliveryAddress?.city}</td>
              <td>{company.deliveryAddress?.region}</td>
              <td>{company.companyId}</td>
              <td>{new Date(company.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Companies;
