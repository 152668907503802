import React, { useState } from 'react';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select
import SkuItem from './SkuItem'; // Import the SkuItem component
import { updateProducerOrderStatus } from '../services/orderService';

const ProducerOrderCard = ({
  order,
  handleSkuClick,
  skus,
}) => {
  // Local state to manage editing mode, selected status, and alerts
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(order.producerOrderStatus);
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });

  // Filter SKUs based on the sellerId matching the order's sellerId
  const filteredSkus = skus.filter((sku) => sku.sellerId === order.sellerId);

  // Options for order status, filtering out specific options if no packedSkus
  const statusOptions = [
    { value: 'AWAITING_PROCESSING', label: 'AWAITING PROCESSING' },
    { value: 'PROCESSING', label: 'PROCESSING' },
    { value: 'NOT_FULFILLED', label: 'NOT FULFILLED' },
    { value: 'PREPARING_FOR_SHIPMENT', label: 'PREPARING FOR SHIPMENT' },
    { value: 'OUT_FOR_DELIVERY', label: 'OUT FOR DELIVERY' },
    { value: 'DELIVERED', label: 'DELIVERED' },
  ];

  // Conditionally filter out specific statuses if packedSkus is not present
  const filteredStatusOptions = order.packedSkus
    ? statusOptions
    : statusOptions.filter(
        (option) =>
          !['PREPARING_FOR_SHIPMENT', 'OUT_FOR_DELIVERY', 'DELIVERED'].includes(option.value)
      );

  // Find the selected status option based on the current producerOrderStatus value
  const selectedStatusOption = filteredStatusOptions.find(
    (option) => option.value === selectedStatus
  );

  // Handle change event for status select
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  // Handle Edit button click
  const handleEdit = () => {
    setIsEditing(true);
    setAlert({ type: '', message: '' }); // Clear any existing alerts
  };

  // Handle Cancel button click
  const handleCancel = () => {
    setSelectedStatus(order.producerOrderStatus); // Reset to the original producer order status
    setIsEditing(false);
    setAlert({ type: '', message: '' }); // Clear any existing alerts
  };

  // Handle Save button click
  const handleSave = async () => {
    setIsSaving(true);
    setAlert({ type: '', message: '' }); // Clear any existing alerts

    try {
      await updateProducerOrderStatus(order.orderId, order.producerOrderId, selectedStatus);
      setAlert({ type: 'success', message: 'Producer order status updated successfully!' });

      setIsEditing(false); // Exit edit mode

      // Reload the page after a successful update
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Optional delay to give the user time to see the success message
    } catch (error) {
      console.error('Failed to update producer order status:', error);
      setAlert({ type: 'danger', message: 'Failed to update producer order status. Please try again.' });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        Producer Order: {order.seller} ({order.sellerId})
      </Card.Header>
      <Card.Body>
        <Row className="g-3 d-flex align-items-stretch mb-3">
          {/* First Row */}
          <Col xs={12} md={6} className="d-flex">
            <Card className="flex-fill rounded" style={{ borderRight: '1px solid #dee2e6' }}>
              <Card.Body>
                <div className="d-flex align-items-center mb-2">
                  <strong className="me-2">Status:</strong>
                  <Select
                    options={filteredStatusOptions} // Use filtered options here
                    value={selectedStatusOption}
                    onChange={handleStatusChange}
                    isDisabled={!isEditing} // Disable the select when not in edit mode
                    className="flex-grow-1"
                  />
                </div>
                <p><strong>Substitution Needed:</strong> {order.skus.some(sku => sku.substitutionNotes) ? 'YES' : 'NO'}</p>
                <p><strong>Substitution Notes:</strong> {order.skus.map(sku => sku.substitutionNotes || 'None').join(', ')}</p>
                <p><strong>Order Notes:</strong> {order.skus.map(sku => sku.orderNotes || 'None').join(', ')}</p>
                <p><strong>Delivery Notes:</strong> {order.deliveryNotes } </p>

                {/* Display an alert for success or error */}
                {alert.message && (
                  <Alert variant={alert.type} className="mt-3">
                    {alert.message}
                  </Alert>
                )}

                {/* Display Edit, Save, and Cancel buttons */}
                {!isEditing ? (
                  <Button variant="secondary" onClick={handleEdit} className="mt-2">
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button variant="danger" onClick={handleCancel} className="mt-2 me-2">
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} className="mt-2" disabled={isSaving}>
                      {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} className="d-flex">
            <Card className="flex-fill rounded" style={{ borderLeft: '1px solid #dee2e6' }}>
              <Card.Body>
                <p><strong>Producer Order ID:</strong> {order.producerOrderId}</p>
                <p><strong>Created:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                <p><strong>Last Updated:</strong> {new Date(order.updatedAt).toLocaleString()}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {order.skus.map((sku, skuIndex) => (
          <SkuItem
            orderId={order.orderId}
            producerOrderId={order.producerOrderId}
            producerOrder={order}
            key={skuIndex}
            sku={sku}
            skus={filteredSkus} // Pass only the filtered SKUs to SkuItem
            handleSkuClick={handleSkuClick}
            isEditing={isEditing} // Pass down isEditing state to SkuItem
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ProducerOrderCard;
