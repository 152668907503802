import React, { useEffect, useState } from "react";
import { Container, Spinner, Alert, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaEye, FaFileInvoiceDollar, FaFileCsv } from "react-icons/fa";
import ReactJson from "react-json-view";
import OrderChargesSummaryComponent from "../../components/OrderChargesSummaryComponent";
import ProducerOrderComponent from "../../components/ProducerOrderComponent";
import OrderOverviewComponent from "../../components/OrderOverviewComponent";
import { getOrderById } from "../../services/orderService";
import { getSkus } from "../../services/skuService";
import InvoiceModal from "../../components/InvoiceModal";
import { CSVLink } from "react-csv";

const OrderDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [order, setOrder] = useState(null);
    const [skus, setSkus] = useState([]);
    const [packedItems, setPackedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showJsonModal, setShowJsonModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    // Extract orderId from the URL
    const query = new URLSearchParams(location.search);
    const orderId = query.get("orderId");

    useEffect(() => {
        const fetchOrderAndSkus = async () => {
            if (!orderId) {
                setError("Order ID is missing from the URL.");
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const orderData = await getOrderById(orderId);
                if (!orderData) {
                    setError("Order not found.");
                    return;
                }

                setOrder(orderData);

                const skusData = await getSkus();
                setSkus(skusData);

                const packedItemsArray = extractPackedItems(orderData);
                setPackedItems(packedItemsArray);
            } catch (err) {
                setError(err.message || "Error fetching order data");
            } finally {
                setLoading(false);
            }
        };

        fetchOrderAndSkus();
    }, [orderId, refreshKey]);

    const extractPackedItems = (order) => {
        const items = [];
        if (order?.orderDetails?.producerOrders) {
            order.orderDetails.producerOrders.forEach((producerOrder) => {
                if (producerOrder.packedSkus) {
                    producerOrder.packedSkus.forEach((packedSku) => {
                        if (packedSku.packedSkuId && packedSku.packedQuantity) {
                            items.push({
                                skuId: packedSku.packedSkuId,
                                quantity: packedSku.packedQuantity,
                            });
                        }
                    });
                }
            });
        }
        return items;
    };

    // Refresh order data when showing the JSON modal
    const handleShowJsonModal = async () => {
        try {
            const updatedOrder = await getOrderById(orderId);
            setOrder(updatedOrder); // Ensure the latest data
            setShowJsonModal(true);
        } catch (err) {
            console.error("Error fetching updated order data for JSON modal:", err);
        }
    };

    // Refresh order data when showing the Invoice modal
    const handleShowInvoiceModal = async () => {
        try {
            const updatedOrder = await getOrderById(orderId);
            setOrder(updatedOrder); // Ensure the latest data
            setShowInvoiceModal(true);
        } catch (err) {
            console.error("Error fetching updated order data for Invoice modal:", err);
        }
    };

    const handleCloseJsonModal = () => setShowJsonModal(false);
    const handleCloseInvoiceModal = () => setShowInvoiceModal(false);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Alert variant="danger">Error: {error}</Alert>
            </Container>
        );
    }

    if (!order) {
        return (
            <Container>
                <Alert variant="warning">No order data available.</Alert>
            </Container>
        );
    }

    const { createdAt, orderStatus, updatedAt, orderSummary, orderDetails, deliveryAddress } = order;

    // Check if order is packed and in COMPLETED state
    const isOrderCompleteAndPacked =
        orderStatus === "COMPLETED";

    // Function to handle order status changes and trigger a refresh
    const handleOrderStatusChange = (updatedOrder) => {
        setOrder(updatedOrder); // Update the order state with the new data
        setRefreshKey((prev) => prev + 1); // Increment refresh key to trigger useEffect
    };

    const generateCSVData = () => {
        if (!order || !skus) return [];

        const csvData = [];
        const headers = [
            "Seller",
            "Buyer",
            "Sku Name",
            "Unit Size",
            "Pack Format",
            "Pack Type",
            "Unit Size Detail",
            "Product size measure",
            "Process",
            "Pack size detail",
            "Pack Size Measure",
            "Price",
            "UOM",
            "Substitute Accepted?",
            "Substitute notes",
            "Order Notes",
            "Buyer Contact Name",
            "Buyer Contact Phone Number",
            "Buyer Details Address line 1",
            "Buyer Details Address line 2",
            "Buyer Details City",
            "Buyer Details Post Code",
            "Buyer Details State",
            "Buyer Details Delivery Notes",
        ];

        csvData.push(headers);

        order?.orderSummary?.cartItems.forEach((cartItem) => {
            cartItem?.items.forEach((item) => {
                const skuId = item.skuId;
                const skuDetail = skus.find((sku) => sku.skuId === skuId) || {};

                const row = [
                    cartItem.sellerName,
                    order.orderDetails.buyerCompanyName,
                    skuDetail.skuName || "",
                    skuDetail?.unitSize || "",
                    skuDetail.packFormat || "",
                    skuDetail.packType || "",
                    skuDetail.unitSizeDetail || "",
                    skuDetail.productSizeMeasure || "",
                    skuDetail.process || "",
                    skuDetail.packSizeDetail || "",
                    skuDetail.packSizeMeasure || "",
                    skuDetail.price || "",
                    skuDetail.uom || "",
                    order.orderDetails.items.find((item) => item.skuId === skuId)?.substitutionNotes ? "YES" : "NO",
                    order.orderDetails.items.find((item) => item.skuId === skuId)?.substitutionNotes || "",
                    order.orderDetails.items.find((item) => item.skuId === skuId)?.orderNotes || "N/A",
                    order.orderDetails?.buyerContactName || "",
                    order.orderDetails?.buyerContactPhoneNumber || "",
                    order.deliveryAddress?.addressLine1 || "",
                    order.deliveryAddress?.addressLine2 || "",
                    order.deliveryAddress?.city || "",
                    order.deliveryAddress?.postcode || "",
                    order.deliveryAddress?.region || "",
                    "", // "Buyer Details Delivery Notes" - if applicable
                ];

                csvData.push(row);
            });
        });

        return csvData;
    };

    return (
        <Container className="content">
            <h1>Order Details</h1>
            <div className="d-flex justify-content-between mb-3">
                <Button variant="secondary" onClick={() => navigate("/orders")}>
                    <FaArrowLeft className="me-2 mb-1" />
                    Back
                </Button>
                <div>
                    <Button variant="info" onClick={handleShowJsonModal} className="me-3">
                        <FaEye className="me-2 mb-1" />
                        View JSON Object
                    </Button>
                    {isOrderCompleteAndPacked ? (
                        <Button variant="primary" onClick={handleShowInvoiceModal}>
                            <FaFileInvoiceDollar className="me-2 mb-1" />
                            View Invoice
                        </Button>
                    ) : (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                    Order needs to be COMPLETED to view Invoice.
                                </Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                {isOrderCompleteAndPacked !== null ? (
                                    <Button
                                        variant="primary"
                                        disabled={!isOrderCompleteAndPacked}
                                        style={!isOrderCompleteAndPacked ? { pointerEvents: "none" } : {}}
                                    >
                                        <FaFileInvoiceDollar className="me-2 mb-1" />
                                        View Invoice
                                    </Button>
                                ) : null}
                            </span>
                        </OverlayTrigger>
                    )}
                    <CSVLink
                        data={generateCSVData()}
                        filename={`order_${orderId}.csv`}
                        className="btn btn-success"
                        target="_blank"
                        style={{ marginLeft: "10px" }}
                    >
                        <FaFileCsv className="me-2 mb-1" />
                        Export CSV
                    </CSVLink>
                </div>
            </div>

            <OrderOverviewComponent
                orderDetails={orderDetails}
                deliveryAddress={deliveryAddress}
                buyerContactName={orderDetails?.buyerContactName}
                buyerTradingName={orderDetails?.buyerTradingName}
                buyerCompanyName={orderDetails?.buyerCompanyName}
                createdAt={createdAt}
                orderId={order.orderId}
                orderStatus={orderStatus}
                updatedAt={updatedAt}
                onOrderStatusChange={handleOrderStatusChange}
            />

            <ProducerOrderComponent
                producerOrders={orderDetails?.producerOrders}
                skus={skus}
                orderSummary={orderSummary}
            />

            <OrderChargesSummaryComponent
                orderSummary={orderSummary}
                skus={orderDetails?.producerOrders.skus}
                postcode={deliveryAddress?.postcode}
                packedItems={packedItems}
                orderId={order?.orderId}
            />

            {/* Modal to display JSON object */}
            <Modal show={showJsonModal} onHide={handleCloseJsonModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Order JSON Object</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ fontSize: "12px" }}>
                        <ReactJson src={order} collapsed={false} enableClipboard={true} displayDataTypes={false} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseJsonModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Invoice Modal */}
            <InvoiceModal
                show={showInvoiceModal}
                handleClose={handleCloseInvoiceModal}
                finalOrderSummary={order?.finalOrderSummary}
                order={order}
            />
        </Container>
    );
};

export default OrderDetails;
