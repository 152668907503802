import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { updateFinalOrderSummary } from "../services/orderService"; // Ensure correct path to the service file

// Stubbed finalOrderSummary with default values to ensure the component renders
const defaultFinalOrderSummary = {
    totalItemCost: 0.0,
    orderTotal: 0.0,
    shipping: {
        shippingCost: 0.0,
        gstOnShipping: 0.0,
    },
    platform: {
        platformFee: 0.0,
        gstOnPlatformFee: 0.0,
    },
};

const OrderChargesSummaryComponent = ({ orderSummary, orderId }) => {
    const [finalOrderSummary, setFinalOrderSummary] = useState(defaultFinalOrderSummary);

    useEffect(() => {
        const fetchFinalOrderSummary = async () => {
            if (!orderId) return; // Exit if orderId is not provided

            try {
                const response = await updateFinalOrderSummary(orderId);

                if (response && response?.success && response?.finalOrderSummary) {
                    setFinalOrderSummary(response.finalOrderSummary);
                } else {
                    console.warn("No final order summary available.");
                }
            } catch (err) {
                console.error("Failed to update final order summary:", err);
                // Do not set the error state, just keep the defaults
            }
        };

        fetchFinalOrderSummary();
    }, [orderId]);

    return (
        <Row className="g-3 d-flex align-items-stretch">
            {/* Original Charges Summary */}
            <Col xs={12} md={6} className="d-flex">
                <Card className="flex-fill rounded" style={{ borderRight: "1px solid #dee2e6" }}>
                    <Card.Header>Original Charges Summary</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col className="text-start">Total Item Cost</Col>
                            <Col className="text-end">${orderSummary?.totalItemCost.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">Shipping Cost</Col>
                            <Col className="text-end">${orderSummary?.shipping?.shippingCost.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">GST on Shipping</Col>
                            <Col className="text-end">${orderSummary?.shipping?.gstOnShipping.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">Platform Fee</Col>
                            <Col className="text-end">${orderSummary?.platform?.platformFee.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">GST on Platform Fee</Col>
                            <Col className="text-end">${orderSummary?.platform?.gstOnPlatformFee.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start fw-bold">Order Total</Col>
                            <Col className="text-end fw-bold">${orderSummary?.orderTotal.toFixed(2)}</Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>

            {/* Final Charges Summary */}
            <Col xs={12} md={6} className="d-flex">
                <Card className="flex-fill rounded" style={{ borderLeft: "1px solid #dee2e6" }}>
                    <Card.Header>Final Charges Summary</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col className="text-start">Total Item Cost</Col>
                            <Col className="text-end">${finalOrderSummary?.totalItemCost.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">Shipping Cost</Col>
                            <Col className="text-end">${finalOrderSummary?.shipping?.shippingCost.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">GST on Shipping</Col>
                            <Col className="text-end">${finalOrderSummary?.shipping?.gstOnShipping.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">Platform Fee</Col>
                            <Col className="text-end">${finalOrderSummary?.platform?.platformFee.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start">GST on Platform Fee</Col>
                            <Col className="text-end">${finalOrderSummary?.platform?.gstOnPlatformFee.toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col className="text-start fw-bold">Order Total</Col>
                            <Col className="text-end fw-bold">${finalOrderSummary?.orderTotal.toFixed(2)}</Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default OrderChargesSummaryComponent;
